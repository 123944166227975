<template>
  <div class="cad_page box100 flexCB">
    <div class="cad_L">
      <div class="cadL_top">
        {{ selectBuildNum }}
<!--        <el-carousel class="buildChange" arrow="always" height="30" :autoplay="false" :initial-index="buildIndex" @change="buildSelect">-->
<!--          <el-carousel-item v-for="item in buildList" :key="item.index">-->
<!--            <p>{{ item.buildname }}</p>-->
<!--          </el-carousel-item>-->
<!--        </el-carousel>-->
      </div>
      <div class="cadL_content boxOther">
        <div class="box100 flexCB">
          <div class="floor_L" ref="floor_L">
            <div class="boxOther" :class="flOkScrollCad === true? 'pad40':''">
              <div class="box100 hidden" :class="flOkScrollCad === true? 'ScrollBox':''">
                <p class="floorNum" v-for="fl of floorList"
                   :key="fl.index"
                   @click="handleSelectFl(fl)"
                   :class="selectFloorNum === fl.label ? 'flActive':''">
                  {{ fl.label }}
                  <span class="floorSign" v-if="fl.isequ"></span>
                </p>
<!--                <p class="floorNum flActive">1F</p>-->
<!--                <p class="floorNum">1F</p>-->
<!--                <p class="floorNum">1F</p>-->
              </div>
            </div>
            <div class="xiaIco1" v-if="flOkScrollCad">
              <span class="iconfont iconxiangxia1"></span>
            </div>
          </div>
          <div class="floor_R" ref="floor_R">
            <div class="boxOther" :class="tlOkScrollCad === true? 'pad40':''">
              <div class="box100 hidden" :class="tlOkScrollCad === true? 'ScrollBox':''">
                <div class="mteItem flexAC" v-for="(item, index) of typeList"
                     :class="selectEquNum === index ? 'eqActive':''"
                     @click="handleEquSelect(item, index)"
                     :key="item.index">
                  <div class="mteImg mteImgS"><span class="iconfont" :class="'icon'+item.equtypesvg"></span></div>
                  <div class="mteNumS"><p>{{ item.equtypename }}</p><p>{{ item.equcfonf }}</p></div>
                </div>
              </div>
            </div>
            <div class="xiaIco1" v-if="tlOkScrollCad">
              <span class="iconfont iconxiangxia1"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cad_R">
      <div class="flexCB box100">
        <div class="cadImgBox flexC">
          <div class="cadBox flexC">
            <div class="cadDiv">
              <img :src="cadImgPath"/>
              <span class="cadSpan" v-for="item in spotList" :key="item.index"
                    :style="{top: item[1] + 'px', left: item[0] + 'px'}"></span>
<!--              <span class="cadSpan"></span>-->
            </div>
          </div>
        </div>
        <div class="cadImgBack" @click="closeCAD">
          <span class="iconfont iconclose"></span><p>关闭CAD</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEquInfo, getEquSpot, getBuildInfo } from '@/http/api/home'
export default {
  props: ['cadFloor'],
  data () {
    return {
      floorList: [
        // { label: '1F', name: 1 }, { label: '2F', name: 2 }, { label: '3F', name: 3 },
        // { label: '4F', name: 4 }, { label: '5F', name: 5 }, { label: '6F', name: 6 },
        // { label: '7F', name: 7 }, { label: '8F', name: 8 }, { label: '9F', name: 9 },
        // { label: '10F', name: 10 }, { label: '11F', name: 11 }, { label: '12F', name: 12 },
        // { label: '13F', name: 13 }, { label: '14F', name: 14 }, { label: '15F', name: 15 }
      ],
      flLength: 0,
      flOkScrollCad: false,
      typeList: [
        // { name: '全部设备', ico: 'equall', value: 123456 },
        // { name: '烟感', ico: 'equa', value: 123456 },
        // { name: '一点通', ico: 'equb', value: 123456 },
        // { name: '一键报警', ico: 'equc', value: 123456 },
        // { name: '可燃气体探测器', ico: 'equd', value: 123456 },
        // { name: '压力计', ico: 'eque', value: 123456 },
        // { name: '可视对讲', ico: 'equf', value: 123456 },
        // { name: '液位仪', ico: 'equg', value: 123456 },
        // { name: '电器火灾', ico: 'equh', value: 123456 },
        // { name: '监测主机', ico: 'equi', value: 123456 },
        // { name: '视频监控', ico: 'equj', value: 123456 },
        // { name: '门磁', ico: 'equk', value: 123456 }
      ],
      tlLength: 0,
      tlOkScrollCad: false,
      buildIndex: 0,
      selectBuildNum: '',
      selectFloorNum: '',
      selectEquNum: 0,
      cadImgPath: '',
      selectTypeNum: '0',
      spotList: [],
      buildList: []
    }
  },
  watch: {
  },
  mounted () {
    const height = this.$refs.floor_L.offsetHeight
    this.flLength = (height / 60).toFixed(0)
    const height1 = this.$refs.floor_R.offsetHeight
    this.tlLength = (height1 / 70).toFixed(0)
  },
  created () {
    this.buildIndex = this.cadFloor.buildIndex // 选择的楼
    this.floorList = this.cadFloor.floorList // 楼层列表
    this.selectFloorNum = this.cadFloor.floorChoose.label // 选择的楼层
    this.selectBuildNum = this.cadFloor.buildName // 建筑体
    this.cadImgPath = this.cadFloor.floorChoose.path // 楼层cad
    this.selectEquNum = 0
    this.IsScroll()
    this.getFloorEqu()
  },
  methods: {
    // // 建筑列表
    // getBuildEntry () {
    //   getBuildInfo({ companyid: this.companyid }).then(res => {
    //     if (res.status === '1') {
    //       this.buildList = res.data
    //     } else {
    //       this.buildList = []
    //     }
    //     console.log(this.buildList)
    //   })
    // },
    // // 选择楼
    // buildSelect (val) {
    //   this.floorList = this.buildList[val].buildfloor
    //   this.selectBuildNum = this.buildList[val].buildname
    //
    //   this.getFloorEqu()
    // },
    // 楼层设备
    getFloorEqu () {
      const params = {
        companyid: this.companyid,
        equ_floor: this.selectBuildNum,
        equ_layer: this.selectFloorNum
      }
      getEquInfo(params).then(res => {
        if (res.status === '1') {
          this.typeList = res.data
          this.getCadSpot()
        } else {
          this.typeList = []
        }
        this.TlIsScrollTwo()
      })
    },
    IsScroll () {
      if (this.floorList.length > this.flLength) {
        this.flOkScrollCad = true
      } else {
        this.flOkScrollCad = false
      }
    },
    TlIsScrollTwo () {
      if (this.typeList.length > this.tlLength) {
        this.tlOkScrollCad = true
      } else {
        this.tlOkScrollCad = false
      }
    },
    // 获取cad点位列表
    getCadSpot () {
      const params = {
        companyid: this.companyid,
        equ_floor: this.selectBuildNum,
        equ_layer: this.selectFloorNum,
        equtype: this.selectTypeNum
      }
      getEquSpot(params).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            this.getSpotLocal(res.data)
          } else {
            this.spotList = []
          }
        } else {
          this.spotList = []
        }
      })
    },
    // 获取点位置
    getSpotLocal (array) {
      var brr = []
      for (var i in array) {
        var point = array[i].equ_cadpoint.split(',')
        brr.push(point)
      }
      this.spotList = brr
    },
    // 点击楼层
    handleSelectFl (item) {
      this.selectFloorNum = item.label
      this.cadImgPath = item.path
      this.selectEquNum = 0
      this.selectTypeNum = 0
      this.getFloorEqu()
    },
    // 点击设备类型
    handleEquSelect (item, index) {
      this.selectEquNum = index
      this.selectTypeNum = item.equtypeid
      this.getCadSpot()
    },
    // 关闭CAD
    closeCAD () {
      this.$emit('CADClose')
    }
  }
}
</script>

<style scoped>
  .cad_page{
    padding: 20px 0px;
    box-sizing: border-box;
  }
  .cad_L{
    width: 290px;
    height: 100%;
    position: relative;
  }
  .cad_R{
    width:calc(100% - 290px);
    width:-webkit-calc(100% - 290px);
    width:-moz-calc(100% - 290px);
    height: 100%;
  }
  .cadL_top{
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 20px;
    background: rgba(136, 172, 252, 0.16);
    font-size: 15px;
    font-weight: 800;
  }
  .cadL_content{
    padding-top: 60px;
  }
  .floor_L{
    width: 80px;
    height: 100%;
    position: relative;
  }
  .floor_R{
    width: 160px;
    height: 100%;
    position: relative;
  }
  .flActive{
    background: rgba(255, 173, 66, 0.16);
    color: #FFAD42;
  }
  .eqActive .mteImgS{
    border: 1px solid #FFAD42;
  }
  .eqActive .mteImgS .iconfont{
    color: #FFAD42;
  }
  .eqActive .mteNumS{
    color: #FFAD42;
  }
  .cadImgBack{
    color: #FFAD42;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    width: 58px;
  }
  .cadImgBack .iconfont{
    font-size: 45px;
  }
  .cadImgBox{
    width:calc(100% - 58px);
    width:-webkit-calc(100% - 58px);
    width:-moz-calc(100% - 58px);
    height: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
  }
  .cadBox{
    width: 860px;
    height: 610px;
    position: relative;
    overflow: hidden;
  }
  .cadDiv{
    width: auto;
    height: auto;
    position: relative;
  }
  .cadDiv img{
    width: 100%;
    height: 100%;
  }
  .cadSpan{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    background: #FFAD42;
    top: 10px;
    left: 10px;
  }
  .buildChange{
    width: 100%;
    height: 30px;
  }
  .buildChange>>>.el-carousel__container{
    height: 30px;
  }
  .buildChange>>>.el-carousel__arrow{
    width: 30px;
    height: 30px;
  }
  .buildChange>>>.el-carousel__arrow i{
    font-size: 15px;
  }
  .buildChange>>>.el-carousel__indicators--horizontal{
    display: none;
  }
  .buildChange>>>.el-carousel__item p{
    margin: 0px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    font-weight: 800;
  }
</style>
