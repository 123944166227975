<template>
  <div class="floor_content box100">
    <div class="floor_top flexCB">
      <!-- 一方中港国际B座-->
      <el-carousel class="buildChange" arrow="always" height="30" :autoplay="false"  @change="buildSelect">
        <el-carousel-item v-for="item in buildList" :key="item.index">
          <p>{{ item.buildname }}</p>
        </el-carousel-item>
      </el-carousel>
      <div class="floorBack" @click="fBack">返回</div>
    </div>
    <div class="floor_cont box100">
      <div class="box100 flexCB">
        <div class="floor_L" ref="floor_L">
          <div class="boxOther" :class="flOkScroll === true? 'pad40':''">
            <div class="box100 hidden" :class="flOkScroll === true? 'ScrollBox':''">
              <p class="floorNum" v-for="fl of floorList" :key="fl.index" @click="selectFloor(fl)">
                {{ fl.label }}F
                <span class="floorSign" v-if="fl.isequ"></span>
              </p>
<!--              <p class="floorNum">1F</p>-->
<!--              <p class="floorNum">1F</p>-->
<!--              <p class="floorNum">1F</p>-->
            </div>
          </div>
          <div class="xiaIco1" v-if="flOkScroll">
            <span class="iconfont iconxiangxia1"></span>
          </div>
        </div>
        <div class="floor_R" ref="floor_R">
          <div class="boxOther" :class="tlOkScroll === true? 'pad40':''">
            <div class="box100 hidden" :class="tlOkScroll === true? 'ScrollBox':''">
              <div class="mteItem flexAC" v-for="item of typeList" :key="item.index">
                <div class="mteImg mteImgC"><span class="iconfont" :class="'icon'+item.equtypesvg"></span></div>
                <div class="mteNum"><p>{{ item.equtypename }}</p><p>{{ item.equcfonf }}</p></div>
              </div>
            </div>
          </div>
          <div class="xiaIco1" v-if="tlOkScroll">
            <span class="iconfont iconxiangxia1"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBuildInfo, getEquInfo } from '@/http/api/home'
export default {
  data () {
    return {
      companyid: '',
      buildList: [],
      buildName: '',
      floorList: [
        // { label: '1F', name: 1 }, { label: '2F', name: 2 }, { label: '3F', name: 3 },
        // { label: '4F', name: 4 }, { label: '5F', name: 5 }, { label: '6F', name: 6 },
        // { label: '7F', name: 7 }, { label: '8F', name: 8 }, { label: '9F', name: 9 },
        // { label: '10F', name: 10 }, { label: '11F', name: 11 }, { label: '12F', name: 12 },
        // { label: '13F', name: 13 }, { label: '14F', name: 14 }, { label: '15F', name: 15 }
      ],
      flLength: 0,
      flOkScroll: false,
      typeList: [
        // { name: '全部设备', ico: 'equall', value: 123456 },
        // { name: '烟感', ico: 'equa', value: 123456 },
        // { name: '一点通', ico: 'equb', value: 123456 },
        // { name: '一键报警', ico: 'equc', value: 123456 },
        // { name: '可燃气体探测器', ico: 'equd', value: 123456 },
        // { name: '压力计', ico: 'eque', value: 123456 },
        // { name: '可视对讲', ico: 'equf', value: 123456 },
        // { name: '液位仪', ico: 'equg', value: 123456 },
        // { name: '电器火灾', ico: 'equh', value: 123456 },
        // { name: '监测主机', ico: 'equi', value: 123456 },
        // { name: '视频监控', ico: 'equj', value: 123456 },
        // { name: '门磁', ico: 'equk', value: 123456 }
      ],
      tlLength: 0,
      tlOkScroll: false,
      buildIndex: 0
    }
  },
  mounted () {
    const height = this.$refs.floor_L.offsetHeight
    this.flLength = (height / 60).toFixed(0)
    const height1 = this.$refs.floor_R.offsetHeight
    this.tlLength = (height1 / 70).toFixed(0)
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.getBuild()
  },
  methods: {
    // 获取建筑
    getBuild () {
      getBuildInfo({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.buildList = res.data
          this.floorList = this.buildList[0].buildfloor
          this.buildName = this.buildList[0].buildname
          this.getEquipList()
          this.IsScroll()
        } else {
          this.buildList = []
        }
      })
    },
    // 切换建筑
    buildSelect (val) {
      this.floorList = this.buildList[val].buildfloor
      this.buildName = this.buildList[val].buildname
      this.buildIndex = val
      this.getEquipList()
    },
    getEquipList () {
      const params = {
        companyid: this.companyid,
        equ_floor: this.buildName,
        equ_layer: ''
      }
      getEquInfo(params).then(res => {
        if (res.status === '1') {
          this.typeList = res.data
          this.IsScrollTwo()
        } else {
          this.typeList = []
        }
      })
    },
    IsScroll () {
      if (this.floorList.length > this.flLength) {
        this.flOkScroll = true
      } else {
        this.flOkScroll = false
      }
    },
    IsScrollTwo () {
      if (this.typeList.length > this.tlLength) {
        this.tlOkScroll = true
      } else {
        this.tlOkScroll = false
      }
    },
    selectFloor (item) {
      // console.log(item)
      var obj = {
        floorList: this.floorList,
        buildName: this.buildName,
        floorChoose: item,
        buildIndex: this.buildIndex
      }
      // console.log(obj)
      this.$emit('floorSelect', obj)
    },
    fBack () {
      this.$emit('hiddenFloor')
    }
  }
}
</script>

<style scoped>
  .floor_top{
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 20px;
    /*background: rgba(136, 172, 252, 0.16);*/
  }
  .floor_cont{
    /*padding-top: 60px;*/
    height: calc(100% - 60px);
    margin-top: 30px;
  }
  .floor_L{
    width: 80px;
    height: 100%;
    position: relative;
  }
  .floor_R{
    width: 160px;
    height: 100%;
    position: relative;
  }
  .buildChange{
    width: 238px;
    height: 30px;
    background: rgba(136, 172, 252, 0.16);
    border-radius: 20px 0px 0px 20px;
  }
  .floorBack{
    width: 50px;
    height: 30px;
    background: rgba(136, 172, 252, 0.16);
    border-radius: 0px 20px 20px 0px;
    font-size: 14px;
    cursor: pointer;
  }
  .buildChange>>>.el-carousel__container{
    height: 30px;
  }
  .buildChange>>>.el-carousel__arrow{
    width: 30px;
    height: 30px;
  }
  .buildChange>>>.el-carousel__arrow i{
    font-size: 15px;
  }
  .buildChange>>>.el-carousel__indicators--horizontal{
    display: none;
  }
  .buildChange>>>.el-carousel__item p{
    margin: 0px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    font-weight: 800;
  }
</style>
