<template>
  <div class="router">
    <!--  首页展示  -->
    <div class="box100" v-show="!showCAD">
      <div class="router_content" id="unitMap">
        <div class="map_mask"></div>
      </div>
      <div class="router_main flexCB">
        <div class="main_left">
          <!-- 设备信息 -->
          <div class="leftBox equ_all" v-if="!showBuild">
            <p class="mt_title">设备信息</p>
            <div class="mt_eqAll boxOther">
              <div class="box100"><EquTypeList></EquTypeList></div>
            </div>
          </div>
          <!-- 建筑楼层信息 -->
          <div class="leftBox equ_all" v-if="showBuild">
            <Floor @floorSelect="floorSelect" @hiddenFloor="hiddenFloor"></Floor>
          </div>
        </div>
        <div class="main_right">
          <div class="rightBox">
            <div class="right_top"><Equana :Refresh="Refresh"></Equana></div>
            <div class="rightT_Other boxOther">
              <div class="box100">
                <Alarm :Refresh="Refresh" @alarmNews="alarmNews"></Alarm>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" :class="bgShow?'mapBg': ''"></div>
    </div>
    <!-- CAD盒子 -->
    <div class="box100" v-if="showCAD">
      <div class="router_main CAD_content"><Cad :cadFloor="cadFloor" @CADClose="CADClose"></Cad></div>
    </div>

    <!--  告警详情  -->
    <el-dialog :visible.sync="alarmDetailShow" class="alarmDetailDialog alarmDialog hdBg" width="1200px" :close-on-click-modal="false">
      <AlarmDetail :alarmKey="alarmKey"
                   @dealHandShow="dealHandShow"
                   @assignHandShow="assignHandShow"
                   @superviseHandShow="superviseHandShow"
                   @misreportHandShow="misreportHandShow"
                   @openImg="openImg"
                   @customerDealShow="customerDealShow"
                   @busGzDeal="busGzDeal"
                   @ysDealShow="ysDealShow">
      </AlarmDetail>
    </el-dialog>

    <!-- 告警详情 弹窗 AI放大图片 -->
    <el-dialog title="抓拍图片" :visible.sync="showBigImg" center width="1000px" class="hdBg hdTb" style="z-index: 999;">
      <div class="bigImgBox">
        <div class="sizeBox" @mousewheel.prevent="rollImg">
          <img :src="bigImgUrl" alt="" class="img" ref="imgDiv" :onerror="notImg" @mousedown="ImgMove"/>
        </div>
      </div>
    </el-dialog>

    <!-- 处理 -->
    <el-dialog title="处理" :visible.sync="dealWithShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <DealWith :alarmOperate="alarmOperate"
                @dealHandCancel="dealHandCancel"
                @dealHandConfirm="dealHandConfirm">
      </DealWith>
    </el-dialog>

    <!-- 分派 -->
    <el-dialog title="分派" :visible.sync="assignShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Assign :alarmOperate="alarmOperate"
              @assignHandCancel="assignHandCancel"
              @assignHandConfirm="assignHandConfirm">
      </Assign>
    </el-dialog>

    <!-- 督办 -->
    <el-dialog title="督办" :visible.sync="superviseShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Supervise :alarmOperate="alarmOperate"
              @superHandCancel="superHandCancel"
              @superHandConfirm="superHandConfirm">
      </Supervise>
    </el-dialog>

    <!--  用户-(隐患)处理-弹框  -->
    <el-dialog title="处理" :visible.sync="customerDealWith" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <CusDealWith :alarmInfo="alarmInfo" @dealClose="dealClose" @busDealSuccess="busDealSuccess"></CusDealWith>
    </el-dialog>

    <!-- 客服-延时处理-弹框 -->
    <el-dialog title="延时处理" :visible.sync="ysDealDialog" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Delayed :alarmInfo="alarmInfo" @delayedClose="delayedClose" @delayedSuccess="delayedSuccess"></Delayed>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js'
import sounds from '@/assets/alarm.mp3'
import Equana from '@/components/home/equana'
import EquTypeList from '@/components/home/equTypeList'
import Alarm from '@/components/home/alarm'
import Floor from '@/components/home/floor'
import Cad from '@/components/home/cad'
import AlarmDetail from '@/components/home/alarmDetail'
import DealWith from '@/components/home/dealWith'
import Assign from '@/components/home/assign'
import Supervise from '@/components/home/supervise'
import CusDealWith from '@/components/customer/dealWith.vue'
import Delayed from '@/components/customer/delayed.vue'
import { getUnitScore } from '@/http/api/home'
import { alarmReport } from '@/http/api/alarm'
export default {
  name: 'home',
  props: ['messageInfo'],
  components: {
    Equana, // 设备分析
    EquTypeList, // 设备信息
    Alarm, // 告警列表
    Floor, // 楼层
    Cad, // CAD
    AlarmDetail, // 告警详情
    DealWith, // 处理
    Assign, // 分派
    Supervise, // 督办
    CusDealWith,
    Delayed
  },
  data () {
    return {
      companyid: '',
      center: [109.498924, 34.412912],
      centerLog: '109.498924',
      centerLat: '34.412912',
      map: null,
      infoWindow: null,
      alarmWindowInfo: null,
      showBuild: false,
      showCAD: false,
      cadFloor: '',
      companyScore: '',
      Refresh: false, // 刷新
      alarmDetailShow: false,
      alarmKey: '',
      dealWithShow: false,
      assignShow: false,
      superviseShow: false,
      alarmOperate: '',
      isAlarmFinish: false,
      bgShow: true,
      showBigImg: false,
      notImg:  'this.src="'+ require('../../assets/img/imgError.png') +'"',
      bigImgUrl: '',
      customerDealWith: false,
      alarmInfo: '',
      ysDealDialog: false,
    }
  },
  watch: {
    messageInfo (val) {
      this.Refresh = !this.Refresh
      // var homepage = JSON.parse(window.localStorage.getItem('loginInfo')).homepage
      // if (homepage === '1') {
      //   this.isAlarmFinish = false
      //   this.mapAlarmWindow() // 告警窗体
      // } else {
      //   this.isAlarmFinish = true
      //   this.getCompanyInfo()
      // }
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    // this.messageInfo = {
    //   msgtype: '3',
    //   senduser: '0f0e4adaa7f511eba71f244bfe7a0fff',
    //   sendname: '超级管理员',
    //   receiveuser: 'a8c53fc2ae0311eba886244bfe7a0fff',
    //   username: '周五',
    //   msgid: 'e8ae2512b2c811eb9b6c244bfe7a0fff',
    //   ectpid: 'HJ2105121022320000017',
    //   title: '火警信息',
    //   content: '烟雾报警',
    //   reportime: '2021-05-12 10:22:32',
    //   companyid: '4e721474ad8c11ebac66244bfe7a0fff',
    //   companyname: '渭南市移动公司',
    //   equid: 'd8bbd4a2ad8e11eb8ca1244bfe7a0fff',
    //   equname: 'NB烟感',
    //   equadderss: '移动大楼1层大厅',
    //   securityadmin: '王五',
    //   securityphone: '13096995252',
    //   equtypesvg: 'equa',
    //   ectpdisp: '2'
    // }
    this.initMap()

    window.goWork = this.goWork  // 地图窗体[单位概况]点击事件
    window.showFool = this.showFool  // 地图窗体[展示CAD]点击事件
  },
  methods: {
    initMap () {
      var that = this
      if (this.map == null) {
        // [高德地图]
        that.map = new AMap.Map('unitMap', {
          center: that.center,
          expandZoomRange: true,
          zoom: 18,
          pitch: 50, // 地图俯仰角度，有效范围 0 度- 83 度
          rotation: 20,
          viewMode: '3D', // 地图模式
          // mapStyle: 'amap://styles/0362e2abe7952f5c8c847c02ae08e15c',
          // mapStyle: 'amap://styles/516ae96c8fcd9801fccf33854c7a6c33',
          buildingAnimation: true, // 楼块出现是否带动画
        })
        that.map.setMapStyle('amap://styles/grey')

        // [天地图]
        // that.map = new T.Map('unitMap')
        // that.map.centerAndZoom(new T.LngLat(108.943551, 34.318167), 18) // 地图中心点
        // that.map.setStyle('indigo') // 设置地图主题
        setTimeout(() => {
          this.bgShow = false
        }, 400)
      }
      this.getCompanyInfo()
    },
    getCompanyInfo () {
      getUnitScore({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.companyScore = res.data
          this.mapWindow() // 单位窗体
        }
      })
    },
    // 单位信息窗体
    mapWindow () {
      var that = this
      // 天地图【窗体】
      // var infoWin1 = new T.InfoWindow()
      // var sContent = '<div class="map-infoWindow">' +
      //                   '<div class="mw_content" onclick="showFool()">' +
      //                     '<div class="mw_cont">' +
      //                         '<p class="mw_title">'+ this.companyScore.companyname +'</p>' +
      //                     '<div class="flexCB">' +
      //                         '<div class="mw_score"><span>80</span>分</div>' +
      //                         '<div class="mwBtn" onclick="goWork()">单位概况</div>' +
      //                     '</div>' +
      //                     '</div>' +
      //                         '<span id="bot"></span>' +
      //                     '</div>' +
      //                 '</div>'
      // infoWin1.setContent(sContent)
      // that.map.openInfoWindow(infoWin1,
      //   new T.LngLat(this.companyScore.companylong, this.companyScore.companylat),
      //   { offset: new T.Point(10, -10) }
      // ) // 打开窗体
      // that.map.centerAndZoom(new T.LngLat(this.companyScore.companylong, this.companyScore.companylat), 18) // 地图中心点 // 同时设置地图层级与中心点

      // 高德地图【自定义窗体】
      var MyComponent = Vue.extend({
        template: `<div class="map-infoWindow">
                     <div class="mw_content" @click="showFool">
                        <div class="mw_cont">
                           <p class="mw_title">{{ companyScore.companyname }}</p>
                           <div class="flexCB" style="padding: 10px 20px;">
                               <div class="mw_score"><span>{{ companyScore.companyscore }}</span>分</div>
                               <div class="mwBtn" @click="goWork">单位概况</div>
                           </div>
                        </div>
                        <span id="bot"></span>
                     </div>
                   </div>`,
        data () {
          return {
            companyScore: that.companyScore
          }
        },
        methods: {
          // 单位概况
          goWork () {
            const obj = { path: 'work', ac: 'work' }
            window.localStorage.setItem('tabActive', JSON.stringify(obj))
            that.$router.push('/work')
          },
          // 窗体点击
          showFool () {
            that.showBuild = true
          }
        }
      })
      var componentEle = new MyComponent().$mount()
      that.infoWindow = new AMap.InfoWindow({
        isCustom: true,
        offset: new AMap.Pixel(10, -10)
      })
      that.infoWindow.setContent(componentEle.$el)
      that.infoWindow.open(that.map, [that.companyScore.companylong, that.companyScore.companylat]) // 打开窗体
      that.map.setZoomAndCenter(18, [that.companyScore.companylong, that.companyScore.companylat]) // 同时设置地图层级与中心点
    },
    goWork () {
      const obj = { path: 'work', ac: 'work' }
      window.localStorage.setItem('tabActive', JSON.stringify(obj))
      this.$router.push('/work')
    },
    // 窗体点击[展示cad]
    showFool () {
      this.showBuild = true
    },
    // 告警弹框
    mapAlarmWindow () {
      var that = this
      var alarmComponent = Vue.extend({
        template: `<div class="alarm-infoWindow" @click="handShowDetail">
            <div class="ai_top">
              <div class="ait_T flexCB">
                <div class="ait_l flexAC">
                  <span class="iconfont iconunit" style="font-size: 20px;"></span>
                  <span>{{alarmInfo.companyname}}</span>
                </div>
                <div class="ait_r">{{alarmInfo.reportime}}</div>
              </div>
              <audio controls="controls" :src="sound" ref="audio" autoplay="autoplay" hidden="true"></audio>
              <div class="ait_I flexAC" style="margin-top: 15px;">
                <div class="equIl">
                  <span class="iconfont" :class="'icon'+alarmInfo.equtypesvg" style="font-size: 50px;"></span>
                  <p>{{alarmInfo.equname}}</p>
                </div>
                <div class="equIm" style="margin: 0px 25px 0px">
                  <p class="equi1"><span class="iconfont iconliebiao"></span><span>{{alarmInfo.content}}</span></p>
                  <p class="equi"><span class="iconfont iconlocation"></span><span>{{alarmInfo.equadderss}}</span></p>
                </div>
                <div class="equIr">
                  <p class="equi1"><span class="iconfont iconrenshu"></span><span>{{alarmInfo.securityadmin}}</span></p>
                  <p class="equi"><span class="iconfont icondianhua"></span><span>{{alarmInfo.securityphone}}</span></p>
                </div>
              </div>
            </div>
            <div class="ai_fot flexCB">
              <div class="alfi2" @click.stop="handDeal">处理</div>
              <div class="alfi2" @click.stop="handAssign">分派</div>
              <div class="alfi2" @click.stop="handMisreport">误报</div>
            </div>
          </div>`,
        data () {
          return {
            alarmInfo: that.messageInfo,
            sound: sounds
          }
        },
        created () {
          // this.$refs.audio.pause()
          this.audioPlay()
        },
        methods: {
          handShowDetail () {
            that.alarmDetailShow = true
            that.alarmKey = that.messageInfo
            // this.$refs.audio.pause()
          },
          audioPlay () {
            // this.$refs.audio.play()
          },
          // 处理
          handDeal () {
            that.dealHandShow(that.messageInfo)
            that.isAlarmFinish = true
            // this.$refs.audio.pause()
          },
          // 分派
          handAssign () {
            that.assignHandShow(that.messageInfo)
            // this.$refs.audio.pause()
          },
          // 误报
          handMisreport () {
            that.isAlarmFinish = true
            that.misreportHandShow(that.messageInfo)
            // this.$refs.audio.pause()
          }
        }
      })
      var componentAla = new alarmComponent().$mount()
      that.alarmWindowInfo = new AMap.InfoWindow({
        isCustom: true,
        offset: new AMap.Pixel(10, -10)
      })
      that.alarmWindowInfo.setContent(componentAla.$el)
      that.alarmWindowInfo.open(that.map, [that.messageInfo.longitude, that.messageInfo.latitude]) // 打开窗体
      that.map.setZoomAndCenter(18, [that.messageInfo.longitude, that.messageInfo.latitude]) // 设置中心点
    },
    // 楼层选择
    floorSelect (res) {
      this.showCAD = true
      this.cadFloor = res
    },
    // 关闭楼层
    hiddenFloor () {
      this.showBuild = false
    },
    // 关闭CAD
    CADClose () {
      this.showBuild = false
      this.showCAD = false
    },
    // 告警列表具体点击
    alarmNews (res) {
      this.alarmDetailShow = true
      this.alarmKey = res
    },
    // 处理展示
    dealHandShow (res) {
      this.dealWithShow = true
      this.alarmOperate = res
    },
    // 处理关闭
    dealHandCancel () {
      this.dealWithShow = false
    },
    // 处理提交
    dealHandConfirm () {
      this.dealWithShow = false
      this.alarmDetailShow = false
      this.Refresh = !this.Refresh
      if (this.isAlarmFinish === true) {
        this.getCompanyInfo()
      }
    },
    // 分派展示
    assignHandShow (res) {
      this.assignShow = true
      this.alarmOperate = res
    },
    // 分派关闭
    assignHandCancel () {
      this.assignShow = false
    },
    // 分派提交
    assignHandConfirm () {
      this.assignShow = false
      this.alarmDetailShow = false
      this.Refresh = !this.Refresh
    },
    // 督办展示
    superviseHandShow (res) {
      this.superviseShow = true
      this.alarmOperate = res
    },
    // 督办关闭
    superHandCancel () {
      this.superviseShow = false
    },
    // 督办提交
    superHandConfirm () {
      this.superviseShow = false
      this.alarmDetailShow = false
      this.Refresh = !this.Refresh
    },
    /* 用户-(故障-其他)处理 */
    busGzDeal () {
      this.alarmDetailShow = false
      this.Refresh = !this.Refresh
      if (this.isAlarmFinish === true) {
        this.getCompanyInfo()
      }
    },
    /* 用户-处理-(隐患)-展示 */
    customerDealShow (res) {
      this.customerDealWith = true
      this.alarmInfo = res
    },
    /* 用户-处理-(隐患)-取消 */
    dealClose () {
      this.customerDealWith = false
    },
    /* 用户-处理-(隐患)-提交 */
    busDealSuccess () {
      this.customerDealWith = false
      this.alarmDetailShow = false
      this.Refresh = !this.Refresh
      if (this.isAlarmFinish === true) {
        this.getCompanyInfo()
      }
    },
    /* 用户-延时处理-(隐患)-展示 */
    ysDealShow (res) {
      this.ysDealDialog = true
      this.alarmInfo = res
    },
    /* 用户-延时处理-(隐患)-取消*/
    delayedClose () {
      this.ysDealDialog = false
    },
    /* 用户-延时处理-(隐患)-提交*/
    delayedSuccess () {
      this.ysDealDialog = false
      this.alarmDetailShow = false
      this.Refresh = !this.Refresh
      if (this.isAlarmFinish === true) {
        this.getCompanyInfo()
      }
    },
    // 误报
    misreportHandShow (res) {
      this.alarmDetailShow = false
      this.Refresh = !this.Refresh
      if (this.isAlarmFinish === true) {
        this.getCompanyInfo()
      }
      // this.$confirm('点击误报后本条告警信息将会自动标记为误报信息，请确定是否误报?', '误报', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   customClass: 'Misinform',
      //   center: true
      // }).then(() => {
      //   alarmReport({ ectpid: res.ectpid }).then(res => {
      //     if (res.status === '1') {
      //       this.$message.success(res.message)
      //       this.alarmDetailShow = false
      //       this.Refresh = !this.Refresh
      //       if (this.isAlarmFinish === true) {
      //         this.getCompanyInfo()
      //       }
      //     } else {
      //       this.$message.success(res.message)
      //     }
      //   })
      // }).catch(() => {})
    },
    /* Ai摄像 */
    openImg (res) {
      this.showBigImg = true
      this.bigImgUrl = res
      this.$nextTick(() => {
        this.$refs.imgDiv.style.left = '0px'
        this.$refs.imgDiv.style.top = '0px'
        this.$refs.imgDiv.style.zoom = "100%"
      })
    },
    rollImg (event) {
      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100
      zoom += event.wheelDelta / 12
      if (zoom >= 80 && zoom <500) {
        this.$refs.imgDiv.style.zoom = zoom + "%"
      }
      return false
    },
    ImgMove (e) {
      e.preventDefault()
      var left = document.querySelector('.sizeBox')
      var img = document.querySelector('.img')
      var x = e.pageX - img.offsetLeft
      var y = e.pageY - img.offsetTop
      left.addEventListener('mousemove', move)
      function move(e) {
        img.style.left = e.pageX - x + 'px'
        img.style.top = e.pageY - y + 'px'
      }
      img.addEventListener('mouseup', function() {
        left.removeEventListener('mousemove', move)
      })
      left.addEventListener('mouseout', function() {
        left.removeEventListener('mousemove', move)
      })
    }
  }
}
</script>

<style scoped>
  #unitMap{
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: hidden;
  }
  .map_mask{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: -webkit-radial-gradient(center, closest-corner, rgba(0,0,0,0.1), rgba(0,0,0,0.5), rgba(0,0,0,1));
  }
  /* 单位信息窗体css */
  #unitMap >>> .tdt-infowindow-content-wrapper, #unitMap >>> .tdt-infowindow-tip{
    background: transparent;
    box-shadow: none;
  }
  #unitMap >>> .tdt-infowindow-close-button{
    display: none;
  }
  #unitMap>>>.map-infoWindow{
    width: 300px;
    height: 100px;
    z-index: 9999;
  }
  #unitMap>>>.mw_content{
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px;
    background: rgba(3, 12, 28, 0.8);
    left: 0px;
    top: 0px;
    -moz-border-radius:8px;-ms-border-radius:8px;-o-border-radius:8px;-webkit-border-radius:8px;
  }
  #unitMap>>>.mw_cont{
    width: 100%;
    height: 100%;
    padding: 10px 7px;
    box-sizing: border-box;
  }
  #unitMap>>>#bot {
    width: 0px;
    height: 0px;
    position: absolute;
    line-height: 0px;
    left: 95px;
    bottom: -15px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid rgba(3, 12, 28, 0.8);
  }
  #unitMap>>>.mw_title {
    font-size: 14px;
    text-align: center;
    color: #42EEFF;
    margin: 0;
  }
  #unitMap>>>.mw_score{
    font-size: 14px;
    color: #A5B7CC;
  }
  #unitMap>>>.mw_score span{
    font-size: 45px;
    font-weight: 600;
    color: #4295FF;
  }
  #unitMap>>>.mwBtn{
    width: 95px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #4295FF;
    font-size: 14px;
    background: url("../../assets/img/unit_btn.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  /* 告警窗体css */
  #unitMap>>>.alarm-infoWindow {
    width: 440px;
    height: 190px;
    z-index: 10;
  }
  #unitMap>>>.ai_top{
    width: 440px;
    height: 150px;
    padding: 20px;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(135deg, rgba(226, 45, 45, 0.8) 0%, rgba(227, 94, 59, 0.7) 100%);
  }
  #unitMap>>>.equi1,#unitMap>>>.equi{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  #unitMap>>>.equi{
    margin-bottom: 0px;
  }
  #unitMap>>>.equi1 .iconfont{
    font-size: 18px;
    margin-right: 4px;
  }
  #unitMap>>>.equi .iconfont{
    font-size: 17px;
    margin-right: 4px;
  }
  #unitMap>>>.ai_fot{
    width: 440px;
    height: 40px;
    background: rgba(9, 18, 35, 0.9);
    border-radius: 0px 0px 8px 8px;
  }
  #unitMap>>>.aifi,#unitMap>>>.alfi2{
    width: 25%;
    height: 20px;
    text-align: center;
    color: #FFAD42;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    border-right: 1px solid rgba(255, 173, 66, 0.3);
  }
  #unitMap>>>.alfi2{
    width: 33.3%;
  }
  #unitMap>>>.alfi2:last-child,#unitMap>>>.alfi:last-child{
    border-right: none;
  }
  .main_left,.main_right{
    height: 100%;
    position: relative;
    padding: 20px 0px;
    box-sizing: border-box;
    /*z-index: 9999;*/
  }
  .main_left{
    width: 290px;
  }
  .main_right{
    width: 460px;
  }
  .leftBox,.rightBox{
    width: 100%;
    height: 100%;
    position: relative;
    /*border: 1px solid #fff;*/
  }
  .mapBg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /*background: #152336;*/
    background: url('../../assets/unit_img/1.png') no-repeat;
    background-size: 100% 100%;
  }
  .mt_title{
    width: 160px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    background: rgba(136, 172, 252, .16);
    border-radius: 20px;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
  }
  .mt_eqAll{
    padding-top: 60px;
  }
  .right_top{
    width: 100%;
    height: 280px;
  }
  .rightT_Other{
    padding-top: 280px;
  }
  .CAD_content{
    background: rgba(0, 0, 0, 1);
  }
</style>
