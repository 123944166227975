<template>
  <!-- 首页[设备信息]  -->
  <div class="equType_content" ref="element">
    <div class="boxOther" :class="okScroll === true? 'pad40':''">
      <div class="box100 hidden" :class="okScroll === true? 'ScrollBox':''">
        <div class="mteItem flexAC" v-for="item of typeList" :key="item.index">
          <div class="mteImg mteImgC"><span class="iconfont" :class="'icon'+item.equtypesvg"></span></div>
          <div class="mteNum"><p>{{ item.equtypename }}</p><p>{{ item.equcfonf }}</p></div>
        </div>
      </div>
    </div>
<!--    <div class="mteItem flexAC">-->
<!--      <div class="mteImg mteImgC"><span class="iconfont iconequa"></span></div>-->
<!--      <div class="mteNum"><p>烟感</p><p>12560</p></div>-->
<!--    </div>-->
    <div class="xiaIco" v-if="okScroll">
      <span class="iconfont iconxiangxia1"></span>
    </div>
  </div>
</template>

<script>
import { getEquInfo } from '@/http/api/home'
export default {
  data () {
    return {
      companyid: '',
      typeList: [
        // { name: '全部设备', ico: 'equall', value: 123456 },
        // { name: '烟感', ico: 'equa', value: 123456 },
        // { name: '一点通', ico: 'equb', value: 123456 },
        // { name: '一键报警', ico: 'equc', value: 123456 },
        // { name: '可燃气体探测器', ico: 'equd', value: 123456 },
        // { name: '压力计', ico: 'eque', value: 123456 },
        // { name: '可视对讲', ico: 'equf', value: 123456 },
        // { name: '液位仪', ico: 'equg', value: 123456 },
        // { name: '电器火灾', ico: 'equh', value: 123456 },
        // { name: '监测主机', ico: 'equi', value: 123456 },
        // { name: '视频监控', ico: 'equj', value: 123456 },
        // { name: '门磁', ico: 'equk', value: 123456 }
      ],
      visualLength: 0,
      okScroll: false
    }
  },
  mounted () {
    const height = this.$refs.element.offsetHeight
    this.visualLength = (height / 70).toFixed(0)
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.IsScroll()
    this.getEquTypeList()
  },
  methods: {
    getEquTypeList () {
      getEquInfo({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.typeList = res.data
        } else {
          this.typeList = []
        }
      })
    },
    IsScroll () {
      if (this.typeList.length > this.visualLength) {
        this.okScroll = true
      } else {
        this.okScroll = false
      }
    }
  }
}
</script>

<style scoped>
.equType_content{
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.pad40{
  padding-bottom: 40px;
}
</style>
